import { Picture, Link } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import s from '../../Home.module.css'
import Arrow from '../Arrow'
import { atobID } from '@lib/utils/tools'
import { refLink } from '../../utils'
import useProduct from '@shopify/product/use-product'
import { useCallback, useState } from 'react'
import { formatCurrency } from '@lib/utils'
import { useCommerce } from '@shopify'
import cn from 'classnames'
import { Mousewheel } from 'swiper'
import { NextArrow, PrevArrow } from '../Components/SwiperArrow'
import useCouponInfo from '@components/product/ProductView/useCouponInfo'
import { useCoupon } from '@commerce/product/use-coupon'

const Price = ({ product }) => {
  const { coupon } = useCoupon({ variant: product.variant })
  const { priceFormatted, discountPriceFormatted } = useCouponInfo({
    product,
    variant: product.variant,
    coupon,
    isDigits: false,
  })
  return (
    <div
      className={cn(
        'mt-3 flex items-end gap-1 leading-[1] min-l:mt-[18px] min-xl:mt-8'
      )}
    >
      {product?.price?.value === 9999999.99 ? (
        <span className="text-[#777]">{shopCommon?.soldOut}</span>
      ) : (
        <>
          <span className={cn('text-[32px] font-semibold')}>
            {discountPriceFormatted || priceFormatted}
          </span>
          {discountPriceFormatted && (
            <span
              className={cn(
                'relative -top-1 font-semibold line-through opacity-50'
              )}
            >
              {priceFormatted}
            </span>
          )}
        </>
      )}
    </div>
  )
}

/**
 * Renders a component that displays the best price for a product.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale for localization.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered component.
 */
const BestPrice = ({ section, relatedProducts }) => {
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()

  // 优化后的代码
  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  const settings = {
    modules: [Mousewheel],
    spaceBetween: section.spaceBetween || 8,
    slidesPerView: 'auto' || section.slidesPerView,
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
        slidesOffsetAfter: 236,
      },
      1440: {
        allowTouchMove: false,
        slidesOffsetAfter: 364,
      },
    },
    onProgress: handleProgress,
    onSwiper: setSwiper,
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
    },
  }

  const { data } = useProduct({
    handles: section.lists?.map((item) => item.handle).join(','),
  })

  const { shop } = useCommerce()

  // 优化后的代码
  const getProduct = useCallback(
    (item) => {
      let _product = relatedProducts.find(
        (product) =>
          product.origin.handle === item.handle &&
          product.origin.sku === item.sku
      )?.result

      if (_product) {
        _product.variant = _product.variants?.find(
          (variant) => variant.sku === item.sku
        )
      } else {
        data?.products?.some((product) => {
          if (product.handle === item.handle) {
            return product.variants?.some((variant) => {
              if (variant.sku === item.sku) {
                _product = { ...product, variant }
                return true
              }
              return false
            })
          }
          return false
        })
      }
      return _product
    },
    [data, relatedProducts]
  )

  return (
    <div className="mt-2 w-full min-l:mt-0 min-l:w-0 min-l:grow">
      {/* <div className="text-right">
        <h2
              className={cn(
                s.title,
                'min-l:mb-6 min-l:text-[30px] min-xl:text-[40px]'
              )}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
        {section.all && (
          <Link
            href={refLink(section.all_link, section?.title, 'all')}
            className="hidden items-center justify-end gap-[2px] font-semibold leading-[1.2] text-[#333] min-l:flex"
          >
            <span>{section.all}</span>
            <Arrow />
          </Link>
        )}
      </div> */}
      {/* {section.subtitle && (
        <p
          className="mt-4 text-center font-medium leading-[1.2]"
          dangerouslySetInnerHTML={{ __html: section.subtitle }}
        ></p>
      )} */}
      {section.lists && (
        <div className="relative h-full">
          <Swiper {...settings} className="h-full">
            {section.lists?.map((item, index) => {
              const product = getProduct(item)
              if (
                !product ||
                !product.variant ||
                !product.variant?.availableForSale
              ) {
                return null
              }
              const image =
                product.variant?.image?.url ||
                (product.images && product.images[0]?.url)
              /* let link = getThemeUrl('/products/' + product?.handle, locale) */
              let link = '/products/' + product?.handle
              let param = link.indexOf('?') > -1 ? '&' : '?'
              link = link + param + 'variant=' + atobID(product.variant?.id)
              link = refLink(link, section.title, index + 1)
              return (
                <SwiperSlide
                  key={index}
                  className={cn(
                    '!h-auto !w-[298px] min-l:!w-[310px] min-xl:!w-[448px]'
                    // s.swiperSlide
                  )}
                >
                  <div
                    className={cn(
                      'relative box-border h-full rounded-xl bg-white px-[24px] pt-16',
                      s.picBox,
                      {
                        'pb-[140px]': section.text_line === 4,
                        'pb-[120px]':
                          section.text_line === 3 || !section.text_line,
                        'pb-[100px]': section.text_line === 2,
                      }
                    )}
                  >
                    {item.tag && (
                      <span className="absolute left-4 top-4 rounded-full bg-[#c0ebf9] px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color">
                        {item.tag}
                      </span>
                    )}
                    <Link
                      href={link}
                      className="mx-auto block w-[230px] min-xl:w-[250px]"
                    >
                      <Picture
                        source={image}
                        className="flex justify-center"
                        imgClassName="h-full !w-auto"
                        alt={item.title || product.title}
                      />
                    </Link>
                    <div className="absolute bottom-0 left-0 w-full p-4 min-xl:p-6">
                      <Link href={link} className="nounderline">
                        <h4
                          className="font-semibold leading-[1.2]"
                          dangerouslySetInnerHTML={{
                            __html: item.title || product.title,
                          }}
                        ></h4>
                      </Link>
                      <Price product={product} />
                      <Link href={link} className="text-[#333]">
                        <p className="mt-3 flex items-center gap-[2px] font-semibold min-l:mt-[18px] min-xl:mt-8">
                          <span>
                            {section.learn_more?.replace(
                              '$count',
                              formatCurrency(
                                shop.moneyFormat,
                                Math.ceil(
                                  product.variant?.price * item.credits_times
                                ),
                                false,
                                true
                              )
                            )}
                          </span>
                          <Arrow />
                        </p>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
            <PrevArrow
              swiper={swiper}
              start={start}
              end={end}
              title={section.title}
            />
            <NextArrow
              swiper={swiper}
              start={start}
              end={end}
              title={section.title}
            />
          </Swiper>
          {/* <div
            className={cn('hidden items-center justify-end gap-4 min-l:flex', {
              '!hidden': start && end,
            })}
          >
            <PrevArrow />
            <NextArrow />
          </div> */}
        </div>
      )}
      {/* {section.all && (
        <div className="mt-9 min-l:hidden">
          <Link
            href={refLink(section.all_link, section?.title, 'all')}
            className="flex items-center justify-center gap-[2px] text-center font-semibold leading-[1.2] text-[#333]"
          >
            <span>{section.all}</span>
            <Arrow />
          </Link>
        </div>
      )} */}
    </div>
  )
}

export default BestPrice
